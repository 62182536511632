<template>
  <div>
    <v-card outlined class="white pa-2">
      <v-alert v-if="!item || !item.AsociadoId" text type="info"
        >El usuario no tiene un asociado configurado.</v-alert
      >
      <v-btn
        class="mr-1"
        text
        :disabled="!item || !item.AsociadoId"
        color="secondary"
        v-if="!editMode"
        @click="setEdit()"
        >Editar</v-btn
      >
       <v-btn
          class="mr-1"
          text
          color="secondary"
          v-if="!editMode"
          @click="dialogContrasena = true;"
          >Cambiar contraseña</v-btn
        >
      <v-btn
        class="mr-1"
        depressed
        color="primary"
        v-if="editMode"
        @click="saveChanges()"
        >Guardar</v-btn
      >
      <v-btn
        text
        class="mr-1"
        depressed
        color="error"
        v-if="editMode"
        @click="cancelEdit()"
        >Cancelar</v-btn
      >
      <div class="ma-4" style="background: white">
        <v-list-item three-line>
          <v-list-item-avatar tile size="150" color="white">
            <v-img
              :src="url.logo"
              contain
              style="
                border-style: inherit;
                border-width: thin;
                border-color: #e4322b;
              "
            >
              <v-row align="start" v-if="editMode">
                <v-col>
                  <v-btn icon color="grey darken-1" @click="pickFile('logo')">
                    <v-icon>camera_alt</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
            <input
              type="file"
              style="display: none"
              ref="image"
              accept="image/*"
              @change="onFilePicked"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="overline mb-4">{{ item.CodigoAsociado }}</div>
            <v-list-item-title class="headline mb-1">{{
              item.Nombre
            }}</v-list-item-title>
            <v-list-item-subtitle>
              <span class="font-weight-bold">Canal:</span>
              {{ item.Canal }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="font-weight-bold">RNC:</span>
              {{ item.RNC }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span class="font-weight-bold">Correo:</span>
              {{ item.Email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <br />
        <v-divider></v-divider>
        <v-card-text>
          <v-row wrap>
            <v-col cols="12" sm="12" class="d-flex">
              <v-text-field
                hide-details
                label="Dirección"
                prepend-inner-icon="room"
                :disabled="!editMode"
                v-model="item.Direccion"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex">
              <v-text-field
                hide-details
                label="Teléfono"
                prepend-inner-icon="phone"
                :disabled="!editMode"
                v-model="item.Telefono"
                mask="(###) ###-####"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex">
              <v-text-field
                hide-details
                label="Persona reponsable"
                prepend-inner-icon="person"
                :disabled="!editMode"
                v-model="item.PersonaResponsable"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="d-flex">
              <div>
                Firma
                <v-img
                  :src="url.firma"
                  max-width="25%"
                  max-height="150"
                  contain
                >
                  <v-row align="start" justify="center" v-if="editMode">
                    <v-btn icon @click="pickFile('firma')">
                      <v-icon>camera_alt</v-icon>
                    </v-btn>
                  </v-row>
                </v-img>
                <input
                  v-if="editMode"
                  type="file"
                  style="display: none"
                  ref="firma_image"
                  accept="image/*"
                  @change="onFilePickedFirma"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex">
              <div>
                Sello
                <v-img
                  :src="url.sello"
                  max-width="25%"
                  max-height="150"
                  contain
                >
                  <v-row align="start" justify="center" v-if="editMode">
                    <v-btn icon @click="pickFile('sello')">
                      <v-icon>camera_alt</v-icon>
                    </v-btn>
                  </v-row>
                </v-img>
                <input
                  v-if="editMode"
                  type="file"
                  style="display: none"
                  ref="sello_image"
                  accept="image/*"
                  @change="onFilePickedSello"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
     <v-dialog v-model="dialogContrasena" persistent max-width="25%">
      <v-card justify-center>
        <v-card-title>
          <span>Cambiar contraseña</span>
        </v-card-title>
         <v-card-text>
          <v-form ref="changePassword" v-if="dialogContrasena">
            <v-row>
              <v-col cols="12">
               
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="changePasswordModel.actualPassword"
                      required
                      label="Contraseña actual"
                      :rules="rules.required"
                      @click:append="showActualPassword = !showActualPassword"
                      :append-icon="showActualPassword ? 'visibility_off' : 'visibility'"
                      :type="showActualPassword ? 'text' : 'password'"
                      v-bind="attrs"
                      v-on="on"
                      ></v-text-field>
                  </template>
                  <span>{{ $store.getters.passwordFormat }}</span>
              </v-tooltip>
              </v-col>

                <v-col cols="12">
                
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="changePasswordModel.password"
                              required
                              label="Nueva contraseña"
                              @blur="claveMatch"
                              :rules="rules.required"
                              @click:append="showPassword = !showPassword"
                              :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                              :type="showPassword ? 'text' : 'password'"
                              v-bind="attrs"
                              v-on="on"
                             ></v-text-field>
                  </template>
                  <span>{{ $store.getters.passwordFormat }}</span>
              </v-tooltip>
              </v-col>

                <v-col cols="12">
                
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                            v-model="changePasswordModel.passwordConfirmation"
                              required
                              label="Confirmar nueva contraseña"
                              @blur="claveMatch"
                              :error-messages="claveMathError"
                              :rules="rules.required"
                              @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                              :append-icon="showPasswordConfirmation ? 'visibility_off' : 'visibility'"
                              :type="showPasswordConfirmation ? 'text' : 'password'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                  </template>
                  <span>{{ $store.getters.passwordFormat }}</span>
              </v-tooltip>
              </v-col>
          
            </v-row>
          </v-form>
          <v-row row v-if="processing">
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            depressed
            color="primary"
            :disabled="processing"
            @click="changePassword()"
            >Cambiar</v-btn
          >
        
          <v-btn depressed text color="error" @click="cancelarCambioContrasena()"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
          :timeout="5000"
          v-model="show"
          :multi-line="true"
          :color="color"
          >{{ mensaje }}</v-snackbar
        >
  </div>
</template>

<script>
import apiAsociado from "@/api/asociados";
import apiSeguridad from "@/api/seguridad";


export default {
  data: () => {
    return {
      editMode: false,
      processing: false,
      dialogContrasena:false,
      show: false,
      showActualPassword:false,
      showPassword:false,
      showPasswordConfirmation:false,
      color: "info",
      mensaje: "",
      item: {},
      infoBackUp: {},
      url: {
        logo: "",
        firma: "",
        sello: "",
      },
      file: {
        logo: [],
        firma: [],
        sello: [],
      },
      fileBackUp: {},
      urlBackUp: {},
      breadcrumb: [
        {
          text: "Perfil",
          disabled: true,
        },
      ],
      changePasswordModel:{
        actualPassword:'',
        password:"",
        passwordConfirmation:'',
      },
      claveMathError: "",
      rules: {
        required: [
          (v) => !!v || "Requerido",
          (v) => (v && v.length >= 6) || "Mínimo 6 caracteres",
        ],
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    displayAsociado() {
      if (this.currentUser && this.currentUser.asociado.codigo) {
        return (
          this.currentUser.asociado.codigo + " - " + this.currentUser.nombre
        );
      } else {
        return "NO SELECCIONADO!";
      }
    },
    displayUser() {
      return this.isRolComisiones
        ? this.currentUser.username
        : this.currentUser.asociado.codigo;
    },
    resize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return "";
      } else {
        return "width: 100%";
      }
    },
  },
  methods: {
    pickFile(tipo) {
      if (tipo === "logo") {
        this.$refs.image.click();
      } else if (tipo === "firma") {
        this.$refs.firma_image.click();
      } else if (tipo === "sello") {
        this.$refs.sello_image.click();
      }
    },
    onFilePicked(e) {
      const file = e.target.files;
      if (file[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(file[0]);
        fr.addEventListener("load", () => {
          this.url.logo = fr.result;
          this.file.logo = file[0];
        });
      } else {
        this.url.logo = "";
        this.file.logo = "";
      }
    },
    onFilePickedFirma(e) {
      const file = e.target.files;
      if (file[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(file[0]);
        fr.addEventListener("load", () => {
          this.url.firma = fr.result;
          this.file.firma = file[0];
        });
      } else {
        this.url.firma = "";
        this.file.firma = "";
      }
    },
    onFilePickedSello(e) {
      const file = e.target.files;
      if (file[0] !== undefined) {
        const fr = new FileReader();
        fr.readAsDataURL(file[0]);
        fr.addEventListener("load", () => {
          this.url.sello = fr.result;
          this.file.sello = file[0];
        });
      } else {
        this.url.sello = "";
        this.file.sello = "";
      }
    },
    getInfo() {
      apiAsociado
        .getInformacionAsociado()
        .then((response) => {

            if(response.data){

              this.item = response.data;

            }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    getAsociadoLogo() {
      apiAsociado
        .getAsociadoLogo()
        .then((response) => {
          self.isExporting = false;
          if (response !== null) {
            var file = new Blob([response.data], {
              type: "image/png",
            });
            var fileURL = URL.createObjectURL(file);
            this.url.logo = fileURL;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    getAsociadoFirma() {
      apiAsociado
        .getAsociadoFirma()
        .then((response) => {
          self.isExporting = false;
          if (response !== null) {
            var file = new Blob([response.data], {
              type: "image/png",
            });
            var fileURL = URL.createObjectURL(file);
            this.url.firma = fileURL;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    getAsociadoSello() {
      apiAsociado
        .getAsociadoSello()
        .then((response) => {
          self.isExporting = false;
          if (response !== null) {
            var file = new Blob([response.data], {
              type: "image/png",
            });
            var fileURL = URL.createObjectURL(file);
            this.url.sello = fileURL;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("setError", true);
          this.$store.dispatch("setErrorMessage", error.message);
        });
    },
    setEdit() {
      this.itemBackUp = Object.assign({}, this.item);
      this.fileBackUp = Object.assign({}, this.file);
      this.urlBackUp = Object.assign({}, this.url);
      this.editMode = true;
    },
    cancelEdit() {
      this.item = this.itemBackUp;
      this.file = this.filesBackUp;
      this.url = this.urlBackUp;
      this.editMode = false;
      const input = this.$refs.image;
      input.type = "text";
      input.type = "file";
      const input2 = this.$refs.firma_image;
      input2.type = "text";
      input2.type = "file";
      const input3 = this.$refs.sello_image;
      input3.type = "text";
      input3.type = "file";
    },
    saveChanges() {
      this.processing = true;
      let data = new FormData();
      data.append("AsociadoId", this.item.AsociadoId);
      data.append("Direccion", this.item.Direccion);
      data.append("Telefono", this.item.Telefono);
      data.append("PersonaResponsable", this.item.PersonaResponsable);
      data.append("fileLogo", this.file.logo);
      data.append("fileFirma", this.file.firma);
      data.append("fileSello", this.file.sello);

      apiAsociado
        .putAsociado(data)
        .then(() => {
          this.getInfo();
          this.editMode = false;
          this.processing = false;
        })
        .catch((error) => {
          this.loading = false;
          this.show = true;
          this.mensaje = error.response.data.Message;
          this.processing = false;
        });
    },
     claveMatch() {
      if (this.changePasswordModel.password !== this.changePasswordModel.passwordConfirmation) {
        this.claveMathError = "La clave no coincide con la anterior";
      } else {
        this.claveMathError = "";
      }
    },
    changePassword() {
      if (this.$refs.changePassword.validate()) {
        if (this.changePasswordModel.password === this.changePasswordModel.passwordConfirmation) {

          this.processing = true;

          var data = new FormData();
          data.append("ClaveActual", this.changePasswordModel.actualPassword);
          data.append("NuevaClave", this.changePasswordModel.password);

          apiSeguridad
            .CambiarClave(data)
            .then(() => {
              this.getInfo();
              this.editMode = false;
              this.processing = false;
              this.dialogContrasena = false;
              this.color = "info";
              this.show = true;
              this.mensaje = "Las credenciales han sido modificadas.";
              this.$refs.changePassword.reset();
            })
            .catch((error) => {
             this.loading = false;
              this.processing = false;
              this.color = "primary";
              this.show = true;
              this.mensaje = error.response.data.Message;
            });
        }
      }
    },
    cancelarCambioContrasena(){
      this.dialogContrasena = false;
      this.$refs.changePassword.reset();
    }
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.getInfo();
    this.getAsociadoLogo();
    this.getAsociadoFirma();
    this.getAsociadoSello();
  },
};
</script>